import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import './styles.css';

import logo from '../../assets/solar_mais_premios/logo_orange.png';
import voucher from '../../assets/solar_mais_premios/voucher.svg';
import calendar from '../../assets/solar_mais_premios/calendar.svg';
import prize from '../../assets/solar_mais_premios/prize.svg';
import UserContext from "../../contexts/UserContext";

function NavBar() {
    const { currentUser } = useContext(UserContext);
    const { pendingPrizes } = useFetchClientsResults();

    const routes = [
        { route: '/numeros', navbar: 'Números', icon: voucher },
        { route: '/resultados', navbar: 'Resultados', icon: calendar, warning: pendingPrizes > 0 },
        { route: '/premios', navbar: 'Prêmios', icon: prize, warning: pendingPrizes > 0 },
    ];

    return (
        <div className="navbar">
            <Link to='/'>
                <img src={logo} className="navbar-logo" alt="promo-logo" />
            </Link>
            {currentUser &&
                <>
                {routes.map((route) => (
                    <Link
                        key={route.navbar}
                        to={route.route}
                        className={`navbar-item ${window.location.href.includes(route.route) ? 'selected' : ''} ${route.warning ? 'warning' : ''}`}
                    >
                        <img src={route.icon} className="navbar-icon" alt={route.navbar}/>
                        {route.navbar}
                        {route.warning && <div class="notification"></div>}
                    </Link>
                    ))}
            </>}
        </div>
    );
}

export default NavBar;
