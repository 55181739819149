import React from 'react';

import logo from '../../../assets/solar_mais_premios/logo_white.png';

import './styles.css';

function PopupSuccess({ handleClose }) {

    return(
        <div className="overlay-bg">
            <div className="popup-success-container">
                <img src={logo} alt="banner-week" className="popup-success-img" />
                <p className="popup-success-title">Cadastro efetuado com sucesso!</p>
                <p className="popup-success-subtitle">Agora basta <strong>fazer um pedido no Solar+</strong> para ganhar <strong>números da sorte</strong> e concorrer aos <strong>prêmios</strong>!</p>
                <button className="cta" onClick={handleClose}>Ok, entendi</button>
            </div>
        </div>
    )
}

export default PopupSuccess;