import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchClientsResults = () => {
    const [pendingPrizes, setPendingPrizes] = useState(0);
    const [results, setResults] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/client/meus-resultados`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                },
            })
            .then((response) => {
                setResults(response.data.data);
                setPendingPrizes(response.data.data.filter((result) => result.status === 'aguardando').length);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setResults]);

    return { results, pendingPrizes };
}

export default useFetchClientsResults;