import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchAllDraws = (promoId) => {
  const [drawsOption, setDrawsOption] = useState([]);
  const [error, setError] = useState(null);
  

  useEffect(() => {
    if (!promoId) {
      return;
    }
    
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/sorteios?promo_id=${promoId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
        },
      })
      .then((response) => {
        setDrawsOption(prev => [
          ...prev,
          ...response.data.semanal,
          ...response.data.mensal,
          ...response.data.final
        ]);
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      });
  }, [promoId]);

  return { drawsOption, error };
};

export default useFetchAllDraws;
