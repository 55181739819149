import React, { useContext, useEffect, useState } from "react";

import DrawBox from "../../components/DrawBox/DrawBox";
import SearchBar from '../../components/TextInput/SearchBar';
import Status from "../../components/Status/Status";

import useAuthClient from '../../hooks/useAuthClient';
import useFetchDraws from "../../hooks/useFetchDraws";
import useFetchResults from "../../hooks/useFetchResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import arrow from '../../assets/icons/arrowdown.png';
import './styles.css';
import UserContext from "../../contexts/UserContext";

function Results() {
    const { currentPromo } = useContext(UserContext);
    useAuthClient();
    useSetPageTitle(`${currentPromo?.label} | Resultados`);

    const [dropdown, setDropdown] = useState(false);
    const [searchInfo, setSearchInfo] = useState('');
    const [drawsWeek, setDrawsWeek] = useState([]);
    const [drawsMonth, setDrawsMonth] = useState([]);
    const [drawsFinal, setDrawsFinal] = useState([]);

    const [sorteio, setSorteio] = useState(null);

    useFetchDraws(currentPromo?.value, setDrawsWeek, setDrawsMonth, setDrawsFinal, setSorteio);
    const results = useFetchResults(sorteio?.id);

    const formatDate = (unformattedDate) => unformattedDate.split('-').reverse().join('/');

    const [resultList, setResultList] = useState(results ?? []);
    console.log(resultList)
    useEffect(() => {
        if (!searchInfo?.length) {
            setResultList(results)
            return;
        }
        
        const filtered = results.filter((i) =>
            String(i.voucher).toLowerCase().startsWith(searchInfo.toLowerCase()) ||
            i.premio.nome.toLowerCase().startsWith(searchInfo.toLowerCase()) ||
            i.razao_social.toLowerCase().startsWith(searchInfo.toLowerCase())
        );
        setResultList(filtered)
    }, [searchInfo, results])

    return(
        <div className="results-background page-container">
            <h1 className="title text-yellow">Selecione o Sorteio e <br />Confira os ganhadores</h1>
            <div className="results-dropdown-element">
                <div className={dropdown ? "results-dropdown-selected" : "results-dropdown"} onClick={() => setDropdown(!dropdown)}>
                    <img src={arrow} alt="arrow-down" className={dropdown ? "results-dropdown-arrow-up" : "results-dropdown-arrow"} />
                    {sorteio && <p>{`Sorteio ${sorteio.tipo.charAt(0).toUpperCase() + sorteio.tipo.slice(1)} - ${formatDate(sorteio.data)}`}</p>}
                    {!sorteio && <p>Selecione um Sorteio</p>}
                </div>
                <div className={dropdown ? "results-dropdown-container-visible" : "results-dropdown-container-hidden"}>
                    <div className={dropdown ? "results-dropdown-content-visible" : "results-dropdown-content-hidden"}>
                    <p className="results-dropdown-subtitle">Sorteios Semanais</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {!drawsWeek.length && <DrawBox
                                key={`Sorteio semanal`}
                                name={`Nenhum sorteio semanal encontrado`}
                                disabled={true}
                            />
                            }
                            {drawsWeek.map(draw => (
                                <DrawBox
                                    key={`Sorteio semanal - ${draw.data}`}
                                    name={`${formatDate(draw.data)}`}
                                    startDate={formatDate(draw.inicio)}
                                    endDate={formatDate(draw.fim)}
                                    selected={draw.selected}
                                    disabled={draw.status === 'nao_realizado'}
                                    onClick={() => {
                                        if (draw.status !== 'nao_realizado') {
                                            setDropdown(false);
                                            setSorteio(draw);
                                        }
                                    }}
                                />
                            ))}
                        </div>
                        <p className="results-dropdown-subtitle">Sorteios Mensais</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {!drawsMonth.length && <DrawBox
                                key={`Sorteio mensal`}
                                name={`Nenhum sorteio mensal encontrado`}
                                disabled={true}
                            />
                            }
                            {
                                drawsMonth.map(draw => (
                                    <DrawBox
                                        key={`Sorteio mensal - ${draw.data}`}
                                        name={`${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (!draw.disabled && !draw.selected) {
                                                if (draw.status !== 'nao_realizado') {
                                                    setDropdown(false);
                                                    setSorteio(draw);
                                                }
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                        <p className="results-dropdown-subtitle">Sorteio Final</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {!drawsFinal.length && <DrawBox
                                key={`Sorteio final`}
                                name={`Nenhum sorteio final encontrado`}
                                disabled={true}
                            />
                            }
                            {
                                drawsFinal.map(draw => (
                                    <DrawBox
                                        key={`Sorteio final - ${draw.data}`}
                                        name={`${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (draw.status !== 'nao_realizado') {
                                                setDropdown(false);
                                                setSorteio(draw);
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {sorteio &&
                <div className="results-table-container">
                    <p className="title text-white">{`Sorteio ${sorteio.tipo.charAt(0).toUpperCase() + sorteio.tipo.slice(1)} - ${formatDate(sorteio.data)}`}</p>
                    <div className="results-table-search-container">
                        <SearchBar
                            value={searchInfo}
                            setValue={setSearchInfo}
                            placeholder="Procure informações"
                        />
                    </div>
                    <div className="card-list">
                    {
                            !!results.length && resultList
                            .map((item, index) => (
                                <div className="card" key={index}>
                                    <span>Número da sorte: <strong>#{item.voucher}</strong></span>
                                    <h2><strong className="title text-white">{item.premio.nome}</strong></h2>
                                    <div className="card-content">
                                        <small>Ganhador: {item.razao_social}</small>
                                    </div>
                                </div>
                            ))
                    }
                    {!!results.length && !resultList.length && <p className="prizes-to-withdraw-subtitle">Nenhum resultado encontrado para a busca.</p>}
                    {!results.length && <p className="prizes-to-withdraw-subtitle">Nenhum resultado encontrado para o sorteio selecionado.</p>}
                </div>
            </div>
            }
        </div>
    )
}

export default Results;