import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import logo from '../../../assets/solar_mais_premios/logo_white.png';

function Popup({ handleClose }) {
    const navigate = useNavigate();

    const handleClick = () => {
        handleClose();
        navigate('/premios')
    }

    return(
        <div className="overlay-bg">
            <div className="popup-container">
                <img src={logo} alt="banner-week" className="popup-img" />
                <div className="popup-cta-container" onClick={handleClick}>
                    <p className="popup-cta-text">Clique aqui</p>
                </div>
            </div>
        </div>
    )
}

export default Popup;