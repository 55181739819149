import { useEffect } from 'react';
import axios from 'axios';

const useFetchDraws = (promoId, setDrawsWeek, setDrawsMonth, setDrawsFinal, setSorteio) => {
  useEffect(() => {
    if (!promoId) {
      return;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/sorteios?promo_id=${promoId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
      })
      .then((response) => {
        setDrawsWeek(response.data.semanal);
        setDrawsMonth(response.data.mensal);
        setDrawsFinal(response.data.final);
        const allDraws = [...response.data.semanal, ...response.data.mensal, ...response.data.final];
        let allHappenedDraws = allDraws.filter((draw) => draw.status !== 'nao_realizado');
        allHappenedDraws.sort((a, b) => new Date(a.data) - new Date(b.data));
        const currentDate = new Date();
        const nearestDraw = allHappenedDraws.reduce((acc, curr) => {
          const accDate = new Date(acc.data);
          const currDate = new Date(curr.data);

          const accDiff = Math.abs(currentDate - accDate);
          const currDiff = Math.abs(currentDate - currDate);

          return accDiff < currDiff ? acc : curr;
        }, null);
        setSorteio(nearestDraw);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [promoId, setDrawsFinal, setDrawsMonth, setDrawsWeek, setSorteio]);

};

export default useFetchDraws;
