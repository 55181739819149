import { useEffect } from 'react';
import axios from 'axios';

const useFetchEachDraw = (promoId, setDrawsWeek, setDrawsMonth, setDrawsFinal, setLoading, setError) => {

  useEffect(() => {
    if (!promoId) {
      return;
    }
    
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/sorteios?promo_id=${promoId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
        },
      })
      .then((response) => {
        setLoading(false);
        setError(false);
        setDrawsWeek(response.data.semanal);
        setDrawsMonth(response.data.mensal);
        setDrawsFinal(response.data.final);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [promoId, setError, setLoading, setDrawsFinal, setDrawsMonth, setDrawsWeek]);

};

export default useFetchEachDraw;
