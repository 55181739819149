import axios from 'axios';

const downloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'resultados-atual');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const tryExportingWinners = (sorteioId, callback) => {
    axios
        .get(`${process.env.REACT_APP_API_URL}/admin/cadastros/export?sorteio_id=${sorteioId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
            },
        })
        .then((response) => {
            console.log(response)
            downloadCsv(response.data.url);
            callback(true);
        })
        .catch((err) => {
            callback(false, err.response.data.message)
        });
}

export default tryExportingWinners;