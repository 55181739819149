import React from 'react';

import close from '../../../assets/icons/close-grey.png';

import './styles.css';

function PopupAddress({ setPopup, address }) {
    const handleClose = () => {
        setPopup(false);
    }
    return(
        <div className="overlay-bg">
            <div className="popup-address-container">
            <img src={close} className="popup-close" alt="close" onClick={handleClose} />
                <div className="popup-address-header">
                    <p>Endereço</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">CEP: </p>
                    <p className="popup-address-info">{address.cep}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">Rua: </p>
                    <p className="popup-address-info">{address.logradouro}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">Número: </p>
                    <p className="popup-address-info">{address.numero}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">Complemento: </p>
                    <p className="popup-address-info">{address.complemento}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">Bairro: </p>
                    <p className="popup-address-info">{address.bairro}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">Estado: </p>
                    <p className="popup-address-info">{address.uf}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">Cidade: </p>
                    <p className="popup-address-info">{address.cidade}</p>
                </div>
                <div className="popup-address-row">
                    <p className="popup-address-item">País: </p>
                    <p className="popup-address-info">Brasil</p>
                </div>
            </div>
        </div>
    )
}

export default PopupAddress;