import home from '../../../assets/icons/home.png';
import table from '../../../assets/icons/table.png';
import exportClients from '../../../assets/icons/export.png';
import luck from '../../../assets/icons/luck.png';
import trophy from '../../../assets/icons/trophy.png';
import history from '../../../assets/icons/history.png';
import adduser from '../../../assets/icons/add-user.png';
import delivery from '../../../assets/icons/delivery.png';

const links = [
    {
        label: 'Home',
        path: 'home',
        icon: home
    },

    {
        label: 'Sorteios',
        path: 'sorteios',
        icon: table
    },

    {
        label: 'Exportar cadastrados',
        path: 'cadastrados',
        icon: exportClients
    },

    {
        label: 'Importar números',
        path: 'numeros',
        icon: luck
    },

    {
        label: 'Importar premiados',
        path: 'resultados',
        icon: trophy
    },

    {
        label: 'Acompanhar resultados',
        path: 'acompanhar',
        icon: delivery
    },

    {
        label: 'Atividade',
        path: 'atividade',
        icon: history
    },

    {
        label: 'Adicionar Admin',
        path: 'adicionar',
        icon: adduser
    },
];

export default links;