import React, { useContext, useState } from "react";

import tryExportingRegistered from "./exports/registereds";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import DefaultLoader from "../../../components/Loader/DefaultLoader";

import './styles.css';
import AdminContext from "../../../contexts/AdminContext";

function RegisteredAdmin() {
    const { currentPromo } = useContext(AdminContext);
    
    useAuthAdmin();
    useSetPageTitle(`${currentPromo?.label ?? 'Promo Solar'} | Admin`);
    
    const [loading, setLoading] = useState(false);
    const [errorExport, setErrorExport] = useState(null);
    const [successExport, setSuccessExport] = useState(null);

    const downloadCsv = () => {
        setLoading(true);
        tryExportingRegistered(currentPromo?.value, (success, error) => {
            setSuccessExport(success ? 'Arquivo exportado com sucesso' : null)
            setErrorExport(error ?? null)
            setLoading(false)
        })
    }

    return(
        <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Exportar clientes cadastrados</p>
            </div>
            <div className="admin-page-button-container">
                <button className="admin-page-primary-button" onClick={() => downloadCsv()}>Baixar</button>
                { loading ? <DefaultLoader /> : false }
            </div>
            <p className="admin-page-error-import">{errorExport}</p>
            <p className="admin-page-success-import">{successExport}</p>
        </div>
    )
}

export default RegisteredAdmin;