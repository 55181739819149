import React, { useContext } from "react";

import './styles.css';
import '../../components/Forms/AddressForm/styles.css'

import Status from "../../components/Status/Status";

import useAuthClient from "../../hooks/useAuthClient";
import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";
import UserContext from "../../contexts/UserContext";



function MyPrizes() {
    const { currentPromo } = useContext(UserContext);

    useAuthClient();
    useSetPageTitle(`${currentPromo?.label} | Prêmios`);

    const { results } = useFetchClientsResults();

    return (
        <div className="prizes-background page-container">
            <h1 className="title text-yellow">Meus prêmios</h1>
            <div className="prizes-table-container">
                <div className="card-list">
                    {
                        results
                            .map((item, index) => (
                                <div className="card" key={index}>
                                    <span>Número da sorte: <strong>#{item.voucher}</strong></span>
                                    <h2><strong className="title text-white">{item.premio.nome}</strong></h2>
                                    <div className="card-content">
                                        <Status status={item.status} />
                                    </div>
                                </div>
                            ))
                    }
                    {!results.length && <p className="prizes-to-withdraw-subtitle title text-white">Você ainda não ganhou<br /> <br />Mas continue comprando, quanto mais números da sorte, mais chances você tem de ganhar! </p>}

                </div>
            </div>
        </div>
    )
}

export default MyPrizes;